import React, { useState, useCallback } from "react";
import "./styles.scss";
import { UITextInput } from "../../../../../../shared/ui-text-input/ui-text-input";
import { UISubmitButton } from "../../../../../../shared/ui-submit-button/ui-submit-button";

export const SendGuess = ({ sendJsonMessage, roomInfo }) => {
  const [guess, setGuess] = useState();

  const onSendGuessClick = useCallback(() => {
    sendJsonMessage({
      cmd: "SEND_GUESS",
      meta: { uuid: roomInfo?.uuid, playerKey: roomInfo?.playerKey, guess },
    });
    setGuess("");
  }, [guess, roomInfo, sendJsonMessage]);

  return (
    <div className="send-guess-screen">
      <h1>Listen closely</h1>
      <p>When do you think was this song released?Enter the year below and tap send!</p>
      <UITextInput
        label="My guess for the year of release is..."
        formProps={{ onChange: ({ target }) => setGuess(target?.value) }}
      />
      <UISubmitButton
        text="That's my guess!"
        onClick={onSendGuessClick}
        disabled={!guess?.length}
        className={"send-guess-button"}
      />
    </div>
  );
};
