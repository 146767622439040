import React, { useState, useEffect, useCallback, useMemo } from "react";
import { PlayerGreeting } from "./components/player-info/player-info";
import { conditionalRunner } from "../../../../../utils";
import { SendGuess } from "./components/send-guess/send-guess";
import logoSmall from "../../../../../assets/logo_small.svg";
import "./styles.scss";
import { PlayerScoreboard } from "../../../host-interface-container/components/host-game-controller/components/player-scoreboard/player-scoreboard";
import { UISubmitButton } from "../../../../shared/ui-submit-button/ui-submit-button";

export const PlayerGameController = ({
  lastJsonMessage,
  sendJsonMessage,
  roomInfo,
  setRoomInfo,
}) => {
  const [showSendGuess, setShowSendGuess] = useState(false);
  const [hasWon, setHasWon] = useState(null);
  const [hasLost, setHasLost] = useState(null);
  const [roomClosed, setRoomClosed] = useState(false);

  const handleScoring = useCallback(
    (meta) => {
      console.log("HANDLE SCORING", meta, roomInfo);
      setRoomInfo((old) => ({ ...old, roomState: meta.roomState }));
      if (meta?.updated?.some((e) => e === roomInfo?.playerName)) {
        setHasWon(true);
        return;
      }
      setHasLost(true);
    },
    [hasWon, hasLost, roomInfo]
  );

  useEffect(() => {
    if (!lastJsonMessage) return;

    const { msg, meta } = lastJsonMessage;

    conditionalRunner(() => null)(
      {
        isMatched: () => msg === "GUESS_RECEIVED",
        run: () => setShowSendGuess(false),
      },
      {
        isMatched: () => msg === "ROUND_START",
        run: () => {
          setShowSendGuess(true);
          setHasWon(false);
          setHasLost(false);
        },
      },
      {
        isMatched: () => msg === "SCORE_UPDATED",
        run: () => {
          handleScoring(meta);
          setRoomInfo((old) => ({ ...old, playerList: meta.players }));
        },
      },
      {
        isMatched: () => msg === "ROOM_CLOSED",
        run: () => setRoomClosed(true),
      },
      {
        isMatched: () => msg === "REMINDER_GUESS",
        run: () => setShowSendGuess(true),
      }
    );
  }, [lastJsonMessage]);

  const gameScreenContent = useMemo(
    () =>
      conditionalRunner(() => null)(
        {
          isMatched: () => roomClosed,
          run: () => (
            <div className="game-state-info guess-sent">
              <h1>Sorry, the room is closed.</h1>
              <p>
                Probably because the host left the game (or lost connection).
              </p>
              <UISubmitButton
                text="Return to start"
                onClick={() => (location.href = location.origin)}
              />
            </div>
          ),
        },
        {
          isMatched: () => roomInfo.roomState === "open",
          run: () => (
            <>
              <PlayerGreeting roomInfo={roomInfo} />
              <div className="game-state-info">
                <p className="main-info">We're still waiting for players.</p>
                <p className="sub-info">Tell the others to hurry up!</p>
              </div>
            </>
          ),
        },
        {
          isMatched: () =>
            roomInfo.roomState === "ROUND_FINISHED" && !hasWon && !hasLost,
          run: () => (
            <div className="game-state-info guess-sent">
              <h1>Waiting...</h1>
              <p>We're waiting for the host to start the next round.</p>
              <p className="smaller">Tell them to hurry up!</p>
            </div>
          ),
        },
        {
          isMatched: () =>
            roomInfo.roomState === "ROUND_IN_PROGRESS" && !showSendGuess,
          run: () => (
            <div className="game-state-info guess-sent">
              <h1>Let's see...</h1>
              <p>
                Once everyone sent their guess the host will show the solution.
                If your guess is right, (or really close to being right) you
                will be awarded one point.
              </p>
              <p className="smaller">No, you can't go back from here :P</p>
            </div>
          ),
        },
        {
          isMatched: () => hasWon,
          run: () => (
            <div className="game-state-info guess-sent">
              <h1>Good job, {roomInfo.playerName}! 🎉</h1>
              <p>
                ...or maybe you were just lucky? Anyway, you scored a point this
                round. Have a look at the scoreboard below!
              </p>
              <p className="waiting-message">
                We're waiting for the host to start the next round.
              </p>
              <p className="smaller">Tell them to hurry up!</p>
            </div>
          ),
        },
        {
          isMatched: () => hasLost,
          run: () => (
            <div className="game-state-info guess-sent">
              <h1>Uhm, no. Sorry 🙃</h1>
              <p>
                Looks like your answer wasn't quite correct. Oh well, for sure
                you'll fo better next time! And to be fair, this one was really
                hard. Have a look at the scoreboard below!
              </p>
              <p className="waiting-message">
                We're waiting for the host to start the next round.
              </p>
              <p className="smaller">Tell them to hurry up!</p>
            </div>
          ),
        },
        {
          isMatched: () => showSendGuess,
          run: () => (
            <SendGuess sendJsonMessage={sendJsonMessage} roomInfo={roomInfo} />
          ),
        }
      ),
    [roomInfo, hasWon, hasLost, showSendGuess, roomClosed]
  );

  return (
    <div className="player-game-container">
      <img src={logoSmall} alt="Logo small" />
      <div className="room-name">
        <p>
          Roomname: <span>{roomInfo.roomName}</span>
        </p>
      </div>
      {gameScreenContent}
      <div className="scorecard-container">
        <p className="scorecard-container-header">
          Here's who you're up against
        </p>
        <PlayerScoreboard players={roomInfo.playerList} isPlayer/>
      </div>
    </div>
  );
};
