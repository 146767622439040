import React, { useEffect, useState } from "react";
import { conditionalRunner } from "../../../utils";
import { JoinRoomForm } from "./components/join-room-form/join-room-form";
import { PlayerGameController } from "./components/player-game-controller/player-game-controller";
import "./styles.scss";
import { UIIntro } from "../../shared/ui-intro/ui-intro";

export const PlayerInterfaceContainer = ({
  sendJsonMessage,
  lastJsonMessage,
}) => {
  const [roomInfo, setRoomInfo] = useState();

  useEffect(() => {
    if (!roomInfo || !Object.keys(roomInfo)?.length) return;
    localStorage.setItem("PUBIFY_CLIENT_ROOMINFO", JSON.stringify(roomInfo));
  }, [roomInfo]);

  useEffect(() => {
    const _data = localStorage.getItem("PUBIFY_CLIENT_ROOMINFO");
    if (!_data) return;
    const data = JSON.parse(_data);
    const roomData = {
      uuid: data.uuid,
      playerName: data.playerName,
      playerKey: data.playerKey,
    };
    console.log("Found room", roomData);
    sendJsonMessage({
      cmd: "PLAYER_RECONNECT",
      meta: { ...roomData },
    });
  }, []);

  useEffect(() => {
    if (!lastJsonMessage) return;

    const { msg, meta } = lastJsonMessage;

    conditionalRunner(() => null)(
      {
        isMatched: () => msg === "JOIN_SUCCESS",
        run: () => setRoomInfo(meta),
      },
      {
        isMatched: () => msg === "ROUND_START",
        run: () =>
          setRoomInfo((infoOld) => ({
            ...infoOld,
            roomState: "ROUND_IN_PROGRESS",
          })),
      },
      {
        isMatched: () => msg === "CLIENT_RECONNECT_ACK",
        run: () => {
          setRoomInfo(meta);
        },
      }, {
        isMatched: () => msg === "PLAYER_KICKED",
        run: () => {
          localStorage.removeItem("PUBIFY_CLIENT_ROOMINFO");
          // TODO: Nicer handling here
          location.href = location.href;
        },
      }
    );
  }, [lastJsonMessage]);

  return (
    <div className="join-room-container">
      {/* <pre>{JSON.stringify(roomInfo, null, 2)}</pre> */}
      {!roomInfo ? (
        <>
          <UIIntro className={"join-room-form-intro"} />
          <JoinRoomForm
            onJoinSuccess={({ roomCode, playerName }) =>
              sendJsonMessage({
                cmd: "JOIN_ROOM",
                meta: { code: roomCode, playerName },
              })
            }
          />
        </>
      ) : (
        <PlayerGameController
          lastJsonMessage={lastJsonMessage}
          sendJsonMessage={sendJsonMessage}
          roomInfo={roomInfo}
          setRoomInfo={setRoomInfo}
        />
      )}
    </div>
  );
};
