import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UISubmitButton } from "../../../../../../shared/ui-submit-button/ui-submit-button";
import { UITextInput } from "../../../../../../shared/ui-text-input/ui-text-input";
import "./styles.scss";

export const CreateRoomForm = ({ onCreateSuccess }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => onCreateSuccess?.(data);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)} className="create-room-form">
      {/* register your input into the hook by invoking the "register" function */}
      <UITextInput
        label="Roomname"
        defaultValue="Name of room"
        formProps={register("roomName", {
          pattern: /^[a-zA-Z\s]*$/,
          required: true,
        })}
      />
      {errors.roomName && <span className="error">Only letters and space</span>}
      <UISubmitButton text="Host game" />
    </form>
  );
};
