import { useEffect, useState } from "react";
import toast from "react-hot-toast";


export const useSpotifyPlayer = ({ spotifyAccessToken }) => {
  const [player, setPlayer] = useState();
  const [deviceId, setDeviceId] = useState();

  useEffect(() => {
    console.log({ player });
  }, [player]);

  useEffect(() => {
    if (!spotifyAccessToken) return;
    const script = document.createElement("script");
    script.src = "https://sdk.scdn.co/spotify-player.js";
    script.async = true;

    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
      const player = new window.Spotify.Player({
        name: "Web Playback SDK",
        getOAuthToken: (cb) => {
          cb(spotifyAccessToken.access_token);
        },
        volume: 0.5,
      });

      setPlayer(player);

      player.addListener("ready", ({ device_id }) => {
        console.log("Ready with Device ID", device_id);
        setDeviceId(device_id);
      });

      player.addListener("not_ready", ({ device_id }) => {
        console.log("Device ID has gone offline", device_id);
      });

      player.on("initialization_error", ({ message }) => {
        console.error("Failed to initialize", message);
      });

      player.on("authentication_error", ({ message }) => {
        console.error("Failed to authenticate", message);
      });

      player.on("account_error", ({ message }) => {
        console.error("Failed to validate Spotify account", message);
      });

      player.on("playback_error", ({ message }) => {
        console.error("Failed to perform playback", message);
      });

      player.addListener("player_state_changed", (state) => {
        console.log({ state });
        dispatchEvent(
          new CustomEvent("PLAYER_STATE_CHANGED", { detail: state })
        );
      });

      player.addListener("autoplay_failed", async () => {
        // eslint-disable-next-line no-console
        toast.error("Spotify error: Autplay failed");
      });

      player.connect();
    };
  }, [spotifyAccessToken]);

  return { player, deviceId };
};
