import React from "react";
import { c } from "../../../../../../../../utils";

const formatDeviation = (deviation) => {
  if (deviation === 0) return "±0";
  if (deviation > 0) return `+${deviation}`;
  return `Off by ${deviation}`;
};

export const PlayerGuess = ({ name, guess, deviation, win }) => {
  return (
    <div className={c("player-score-entry", win && "win")}>
      <p className="player-name">{name}</p>
      <p className="guess">
        {guess} <span className="small">({formatDeviation(deviation)})</span>
      </p>
    </div>
  );
};
