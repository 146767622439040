import { GameManager } from "./components/game-manager/game-manager.jsx";
import { Toaster } from "react-hot-toast";
import {
  createBrowserRouter,
  Router,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import { useEffect } from "react";

const SpotifyCallbackHandler = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate({ pathname: "/host", search: location.search });
    console.log(location);
  }, []);
  return null;
};

function App() {
  const router = createBrowserRouter([
    { path: "/spotify_callback", element: <SpotifyCallbackHandler /> },
    { path: "/:clientType", element: <GameManager /> },
    { path: "/", element: <GameManager /> },
  ]);
  return (
    <>
      <div className="App">
        <Toaster
          position="bottom-center"
          reverseOrder
          toastOptions={{
            style: {
              background: "#FC5AD8",
              color: "#ffffff",
              fontSize: "18px",
              // fontWeight: "700",
              fontFamily: `"Prompt", sans-serif`,
            },
            success: {
              iconTheme: {
                primary: "white",
                secondary: "#FC5AD8",
              },
              style: {
                background: "#FC5AD8",
                color: "#ffffff",
                fontSize: "18px",
                // fontWeight: "700",
                fontFamily: `"Prompt", sans-serif`,
              },
            },
          }}
        />
        <div className="manager-container">
          <RouterProvider router={router} />
        </div>
      </div>
    </>
  );
}

export default App;
