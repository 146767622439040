import React, { useId } from "react";
import { c } from "../../../utils";
import "./styles.scss";

export const UIIcon = ({ name, className, onClick }) => {
  const key = useId();
  return (
    <div className={c("ui-icon", className)} onClick={onClick}>
      <i key={`${key}-${name}`} className={`fas fa-${name}`}></i>
    </div>
  );
};
