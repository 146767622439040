import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";

export const useSpotifySongs = ({ fetchSpotify }) => {
  const [playlists, setPlaylists] = useState();
  const [songs, setSongs] = useState();
  useEffect(() => {
    if (!fetchSpotify || playlists?.length) return;
    fetchSpotify("https://api.spotify.com/v1/users/basti10043/playlists").then(
      (res) => {
        if (res?.error) {
          Sentry.captureException(res.error);
          toast.error(res?.error?.message);
          return;
        }
        const totalCount = res.total;
        const pages = Math.ceil(totalCount / 20);
        const promises = [];
        for (let i = 1; i < pages; i++) {
          promises.push(
            new Promise((resolve) => {
              fetchSpotify(
                `https://api.spotify.com/v1/users/basti10043/playlists?${new URLSearchParams(
                  { offset: i * 20 }
                ).toString()}`
              ).then((res) => resolve(res.items));
            })
          );
        }
        Promise.all(promises).then((results) => {
          setPlaylists(
            [...res.items, ...results.flat()].filter((playlist) =>
              playlist.name.includes("[PUB]")
            )
          );
        });
      }
    );
  }, [fetchSpotify]);

  useEffect(() => {
    if (!playlists?.length || songs?.length) return;
    const url = playlists[0].tracks.href;
    fetchSpotify(url).then((res) => {
      setSongs(
        res.items.map((track) => {
          return {
            uri: track.track.uri,
            date: track.track.album.release_date,
            year: track.track.album.release_date.slice(0, 4),
            name: track.track.name,
            artists: track.track.artists.map(({ name }) => name),
          };
        })
      );
    });
  }, [playlists]);

  useEffect(() => {
    console.log("Songs available", { songs });
    toast.success(`${songs?.length} songs loaded!`);
  }, [songs]);

  return { songs };
};
