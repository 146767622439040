import React, { useEffect, useState } from "react";
import { UIIcon } from "../../../../../../shared/ui-icon/ui-icon";
import { c } from "../../../../../../../utils";
import "./styles.scss";

export const SongPlayer = ({
  disabled,
  onPauseClick,
  onPlayClick,
  onWarningClick,
}) => {
  const [playerState, setPlayerState] = useState("paused");

  useEffect(() => {
    const onChanged = ({ detail }) => {
      if (detail?.paused) {
        setPlayerState("paused");
        return;
      }
      if (detail?.loading) {
        setPlayerState("loading");
        return;
      }
      setPlayerState("playing");
    };

    addEventListener("PLAYER_STATE_CHANGED", onChanged);

    return () => {
      removeEventListener("PLAYER_STATE_CHANGED", onChanged);
    };
  }, []);
  return (
    <div className={c("sound-control", disabled && "disabled")}>
      {playerState === "playing" && (
        <UIIcon name={"pause-circle"} onClick={onPauseClick} />
      )}
      {playerState === "paused" && (
        <UIIcon name={"play-circle"} onClick={onPlayClick} />
      )}
      {playerState === "loading" && (
        <UIIcon name={"exclamation-circle"} onClick={onWarningClick} />
      )}
    </div>
  );
};
