import React from "react";
import { c } from "../../../utils";
import "./styles.scss";

export const UIButton = ({
  label = "Button",
  onClick,
  disabled,
  className,
}) => {
  return (
    <button
      className={c("ui-button", className)}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
