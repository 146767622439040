import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { UITextInput } from "../../../../shared/ui-text-input/ui-text-input";
import { UISubmitButton } from "../../../../shared/ui-submit-button/ui-submit-button";

export const JoinRoomForm = ({ onJoinSuccess }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => onJoinSuccess?.(data);

  // console.log(watch("example")); // watch input value by passing the name of it

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* register your input into the hook by invoking the "register" function */}
      <UITextInput
        label={"Roomcode"}
        placeholder={"Roomcode"}
        formProps={register("roomCode", {
          pattern: /^[a-z]+(-[a-z]+)*$/,
          validate: (value) => (value.match(/-/g) || []).length === 2,
          required: true,
        })}
      />
      {errors.roomCode && <span>Wrong room code</span>}

      <UITextInput
        label={"Playername"}
        placeholder={"Playername"}
        formProps={register("playerName", {
          pattern: /^[a-zA-Z\s]*$/,
          required: true,
        })}
      />
      {errors.playerName && <span>Only letters and space</span>}

      <UISubmitButton text={"Join room"} />
    </form>
  );
};
