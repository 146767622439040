import React from "react";
import { c } from "../../../../../../../utils";
import { UIIcon } from "../../../../../../shared/ui-icon/ui-icon";
import "./styles.scss";
import { useScoreboard } from "./use-scoreboard";

const PlayerEntry = ({ player, onPlayerKickClick, isPlayer }) => {
  return (
    <div className="player-entry">
      <div className={c("place-badge", player?.place === 1 && "first")}>
        <p>{player?.place}</p>
      </div>
      <div className="player-name">
        <p>{player.name}</p>
      </div>
      <div className="player-score">
        <p>{player.score} Points</p>
      </div>
      {!isPlayer && (
        <>
          <div className={c("player-guess", player?.guess && "received")}>
            <UIIcon name={player?.guess ? "check-circle" : "clock"} />
          </div>
          <div
            className="kick-player"
            onClick={() => onPlayerKickClick?.(player.name)}
          >
            <UIIcon name={"skull-crossbones"} />
          </div>
        </>
      )}
    </div>
  );
};

export const PlayerScoreboard = ({
  players,
  guesses,
  onPlayerKickClick,
  isPlayer,
}) => {
  const { playersSorted } = useScoreboard({ players, guesses });
  return (
    <div className="card player-scoreboard">
      <h2 className="label">Players</h2>
      {!playersSorted?.length ? (
        <div className="players-empty">
          <p>No one here yet :(</p>
        </div>
      ) : (
        <div className="players-list">
          {playersSorted?.map((player, index) => {
            return (
              <PlayerEntry
                player={player}
                key={`${player.name}-${index}`}
                onPlayerKickClick={onPlayerKickClick}
                isPlayer={isPlayer}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
