import React from "react";
import logo from "../../../assets/logo.svg";
import { UIIntro } from "../../shared/ui-intro/ui-intro";
import "./styles.scss";

export const ClientTypeSelector = ({ setClientType, connected }) => {
  return (
    <div className="client-type-selector">
      <UIIntro />
      <div className="button-container">
        <button
          className={"big join-game"}
          onClick={() => setClientType("PLAYER")}
          disabled={!connected}
        >
          Join game
        </button>
        <button
          className={"big host-game"}
          onClick={() => setClientType("HOST")}
          disabled={!connected}
        >
          Host game
        </button>
      </div>
    </div>
  );
};
