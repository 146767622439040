import React, { useEffect, useState } from "react";
import { PlayerScoreboard } from "./components/player-scoreboard/player-scoreboard";
import { SongPlayer } from "./components/song-player/song-player";
import { UIIcon } from "../../../../shared/ui-icon/ui-icon";
import logoSmall from "../../../../../assets/logo_small.svg";
import "./styles.scss";
import { UIButton } from "../../../../shared/ui-button/ui-button";
import { RevealContainer } from "./components/reveal-container/reveal-container";

export const HostGameController = ({
  players,
  onNextRoundClick,
  guessingFinished,
  revealWinners,
  onShowSolutionClick,
  roundEnded,
  roomInfo,
  guesses,
  roundNumber,
  currentSong,
  winners,
  onPauseClick,
  onPlayClick,
  onWarningClick,
  onPlayerKickClick,
}) => {
  const showSolutionDisabled =
    roomInfo?.roomState === "open" ||
    roomInfo?.roomState === "ROUND_FINISHED" ||
    !guessingFinished;
  const nextRoundDisabled = roomInfo?.roomState === "ROUND_IN_PROGRESS";
  return (
    <>
      <div className={"game-dashboard"}>
        <div className="logo-container">
          <img src={logoSmall} />
        </div>
        <div className="dashboard">
          <div className="start-container">
            <div className="room-info card">
              <div className="card-entry">
                <h2 className="label">Roomcode</h2>
                <p className="content">{roomInfo.code}</p>
              </div>
              <div className="card-entry">
                <h2 className="label">Roomname</h2>
                <p className="content">{roomInfo.roomName}</p>
              </div>
              <div className="card-entry">
                <h2 className="label">Round number</h2>
                <p className="content">{roundNumber}</p>
              </div>
            </div>
            <div className="card game-controls">
              <h2 className="label">Game controls</h2>
              <SongPlayer
                disabled={roundNumber === 0}
                onPauseClick={onPauseClick}
                onPlayClick={onPlayClick}
                onWarningClick={onWarningClick}
              />
              <div className="button-bar">
                <UIButton
                  label="Show solution"
                  onClick={onShowSolutionClick}
                  disabled={showSolutionDisabled}
                />
                <UIButton
                  label="Next round"
                  onClick={onNextRoundClick}
                  disabled={nextRoundDisabled}
                />
              </div>
            </div>
            <PlayerScoreboard
              players={players}
              guesses={guesses}
              onPlayerKickClick={onPlayerKickClick}
            />
          </div>
          {roundEnded && (
            <RevealContainer
              currentSong={currentSong}
              guesses={guesses}
              winners={winners}
            />
          )}
        </div>
        {/* <SongPlayer /> */}
      </div>
    </>
  );
};
