import React from "react";
import { c } from "../../../utils";
import "./styles.scss";

export const UISubmitButton = ({ text, className, onClick, disabled }) => {
  const buttonProps = onClick ? { onClick } : { type: "submit" };

  return (
    <input
      className={c("button submit-button", className)}
      value={text}
      disabled={disabled}
      {...buttonProps}
    />
  );
};
