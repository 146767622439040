import { useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";

const findClosestMatches = (data, solution) => {
  solution = parseInt(solution);

  // Calculate the absolute difference between "value" (as an integer) and "solution"
  const closestMatches = data.reduce((result, item) => {
    const itemValueAsInt = parseInt(item.value);
    if (!isNaN(itemValueAsInt)) {
      const difference = Math.abs(itemValueAsInt - solution);
      if (!result.length || difference < result[0].difference) {
        return [{ item, difference }];
      } else if (difference === result[0].difference) {
        return [...result, { item, difference }];
      }
    }
    return result;
  }, []);

  // Extract the items with the closest differences
  const closestItems = closestMatches.map((match) => ({
    ...match.item,
    points: match?.difference === 0 ? 2 : 1,
  }));

  return closestItems;
};

export const usePlayerScoring = ({
  players,
  roomInfo,
  sendJsonMessage,
  currentSong,
}) => {
  const [guesses, setGuesses] = useState([]);
  const [guessingFinished, setGuessingFinished] = useState();
  const [winners, setWinners] = useState();

  const appendGuess = (guess) => {
    setGuesses((old) => {
      if (old.some(({ player }) => player === guess.player.name)) return old;

      const newGuesses = [...old];
      newGuesses.push({ player: guess.player.name, value: guess.guess });

      const playersWithoutGuess = players.filter(
        ({ name }) => !newGuesses.find(({ player }) => player === name)
      );
      if (!playersWithoutGuess.length) {
        setGuessingFinished(true);
      }
      return newGuesses;
    });
  };

  useEffect(() => {
    if (!guesses?.length) return;

    const playersWithoutGuess = players.filter(
      ({ name }) => !guesses.find(({ player }) => player === name)
    ).length;
    if (!playersWithoutGuess) {
      setGuessingFinished(true);
    }
  }, [players]);

  const revealWinners = useCallback(() => {
    if (!guessingFinished) return;

    const winners = findClosestMatches(guesses, parseInt(currentSong.year));

    setWinners(winners);

    sendJsonMessage({
      cmd: "UPDATE_SCORE",
      meta: {
        uuid: roomInfo?.uuid,
        newScores: winners.map((winner) => ({
          player: winner.player,
          value: players.find((p) => p.name === winner.player)?.score + winner.points,
        })),
      },
    });
  }, [guessingFinished, guesses, sendJsonMessage]);

  const resetScoring = () => {
    setGuesses([]);
    setGuessingFinished(false);
    setWinners([]);
  };

  useEffect(() => {
    if (!guessingFinished) return;
    toast.success("All guesses received!");
  }, [guessingFinished]);

  const hasPlayerGuessed = useCallback(
    (name) => {
      return guesses.some(({ player }) => player === name);
    },
    [guesses]
  );

  return {
    guesses,
    setGuesses,
    resetScoring,
    appendGuess,
    guessingFinished,
    revealWinners,
    winners,
    hasPlayerGuessed,
  };
};
