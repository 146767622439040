import React from "react";

export const TrackMeta = ({ currentSong }) => {
  return (
    <div className="card track">
      <p className="track-info">{currentSong?.name}</p>
      <p className="track-info-artist">{currentSong?.artists.join(", ")}</p>
      <p className="track-year">{currentSong?.year}</p>
    </div>
  );
};
