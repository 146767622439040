export const conditionalRunner = (defaultFn) => {
  return (...conds) => {
    if (conds.some(({ isMatched }) => isMatched())) {
      const truthy = conds.find(({ isMatched }) => isMatched());
      return truthy?.run();
    } else {
      return defaultFn();
    }
  };
};

export const c = (...args) => args.filter((arg) => !!arg).join(" ");

export const splitArray = (input, size = 3) => {
  return input.reduce((acc, curr, i) => {
    if (i % size === 0) {
      acc.push([curr]);
      return acc;
    }
    acc[Math.floor(i / size)].push(curr);
    return acc;
  }, []);
};
