import React from "react";
import logo from "../../../assets/logo.svg";
import { c } from "../../../utils";
import "./styles.scss";

export const UIIntro = ({ className }) => {
  const introContent = (
    <>
      <img src={logo} />
      <p className="tagline">
        The least fun you could possibly have at a party. Promise!
      </p>
    </>
  );

  return className ? (
    <div className={className}>{introContent}</div>
  ) : (
    introContent
  );
};
