import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Base64 } from "base64-string";
import * as Sentry from "@sentry/react";

const getSpotifyClientID = () => import.meta.env.VITE_SPOTIFY_CLIENT_ID;
const getSpotifyClientSecret = () => import.meta.env.VITE_SPOTIFY_CLIENT_SECRET;

var generateRandomString = function (length) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const REFRESH_TOKEN_STORAGE_KEY = "PUBIFY_SPOTIFY_REFRESH_TOKEN";
export const useSpotifyAuth = () => {
  const initToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  const [searchParams] = useSearchParams();
  const [spotifyAuthCode, setSpotifyAuthCode] = useState();
  const [spotifyAuthCodeState, setSpotifyAuthCodeState] = useState();
  const [spotifyAccessToken, setSpotifyAccessToken] = useState();
  const [spotifyRefreshToken, setSpotifyRefreshToken] = useState(
    initToken || undefined
  );
  const accessTokenInitialized = useRef();
  const b64 = new Base64();
  const fetchingToken = useRef();
  const refreshInterval = useRef();

  const showSpotifyAuth = () => {
    const scope =
      "streaming user-read-email user-read-private user-modify-playback-state";

    const state = generateRandomString(16);

    const authQueryParams = new URLSearchParams({
      response_type: "code",
      client_id: getSpotifyClientID(),
      scope: scope,
      redirect_uri: `${location.origin}/spotify_callback`,
      state,
    });

    location.href =
      "https://accounts.spotify.com/authorize/?" + authQueryParams.toString();
  };

  useEffect(() => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (code && state) {
      setSpotifyAuthCode(code);
      setSpotifyAuthCodeState(state);

      if (fetchingToken.current) return;
      fetchingToken.current = true;
      fetch("https://accounts.spotify.com/api/token", {
        method: "POST",
        body: new URLSearchParams({
          code,
          redirect_uri: `${location.origin}/spotify_callback`,
          grant_type: "authorization_code",
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${b64.urlEncode(
            `${getSpotifyClientID()}:${getSpotifyClientSecret()}`
          )}`,
        },
      }).then((res) => {
        res.json().then((res) => {
          setSpotifyAccessToken(res);
          if (res?.refresh_token) {
            setSpotifyRefreshToken(res.refresh_token);
          }
          localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, res.refresh_token);
        });
      });
    }
  }, []);

  const fetchRefreshToken = useCallback(() => {
    fetch("https://accounts.spotify.com/api/token", {
      method: "POST",
      body: new URLSearchParams({
        refresh_token: spotifyRefreshToken,
        grant_type: "refresh_token",
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${b64.urlEncode(
          `${getSpotifyClientID()}:${getSpotifyClientSecret()}`
        )}`,
      },
    }).then((res) => {
      res.json().then((res) => {
        setSpotifyAccessToken(res);
      });
    });
  }, [spotifyRefreshToken]);

  // Refresh token after expiry
  useEffect(() => {
    if (!spotifyRefreshToken) return;

    if (refreshInterval.current) {
      clearInterval(refreshInterval.current);
    }

    if (!accessTokenInitialized.current) {
      fetchRefreshToken();
      accessTokenInitialized.current = true;
    }

    refreshInterval.current = setInterval(fetchRefreshToken, 3600 * 1000);
  }, [fetchRefreshToken]);

  const fetchSpotify = useCallback(
    (url, options) => {
      return new Promise((resolve, reject) => {
        try {
          const Authorization = `Bearer ${spotifyAccessToken?.access_token}`;
          const finalOptions = options?.headers
            ? { ...options, headers: { ...options.headers, Authorization } }
            : { ...options, headers: { Authorization } };

          fetch(url, finalOptions).then((res) => {
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return res.json().then((data) => {
                resolve(data);
              });
            } else {
              return res.text().then((text) => {
                resolve(text || null);
              });
            }
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    [spotifyAccessToken]
  );

  return {
    showSpotifyAuth,
    isSpotifyAuthenticated: Boolean(spotifyAccessToken),
    spotifyAccessToken,
    fetchSpotify: spotifyAccessToken ? fetchSpotify : null,
    spotifyAuthCode,
    spotifyAuthCodeState,
  };
};
