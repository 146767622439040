import React, { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

export const usePlayerlist = ({ sendJsonMessage, roomInfo }) => {
  const [players, setPlayers] = useState();
  const [latestPlayer, setLatestPlayer] = useState();

  useEffect(() => {
    if (!latestPlayer) return;
    toast.success(`Hi, ${latestPlayer}! 👋`)
    setLatestPlayer(null);
  }, [latestPlayer]);

  const onPlayerKickClick = (name) => {
    setPlayers((old) => old.filter((player) => player.name !== name));
    sendJsonMessage({
      cmd: "KICK_PLAYER",
      meta: {
        uuid: roomInfo?.uuid,
        hostKey: roomInfo?.hostKey,
        playerName: name,
      },
    });
  };

  return { players, setPlayers, setLatestPlayer, onPlayerKickClick };
};
