import React, { useEffect, useState, useRef } from "react";
import { HostGameController } from "./components/host-game-controller/host-game-controller";
import { c, conditionalRunner } from "../../../utils";
import { usePlayerlist } from "./hooks/use-playerlist.jsx";
import { usePlayerScoring } from "./hooks/use-players";
import { CreateRoomStep } from "./components/create-room-step/create-room-step";
import { useSpotifyIntegration } from "./hooks/use-spotify-integration";
import "./styles.scss";
import { UIIcon } from "../../shared/ui-icon/ui-icon";
import { CSSTransition } from "react-transition-group";
import { useHostHeartbeat } from "./hooks/use-host-heartbeat";
import toast from "react-hot-toast";

export const HostInterfaceContainer = ({
  sendJsonMessage,
  lastJsonMessage,
}) => {
  const [roomInfo, setRoomInfo] = useState();
  const [roundNumber, setRoundNumber] = useState(0);
  const {
    isSpotifyAuthenticated,
    player,
    songs,
    fetchSpotify,
    showSpotifyAuth,
    deviceId,
  } = useSpotifyIntegration();
  const { players, setPlayers, setLatestPlayer, onPlayerKickClick } =
    usePlayerlist({ sendJsonMessage, roomInfo });
  const currentSong = songs?.[roundNumber - 1] || null;
  const {
    guesses,
    resetScoring,
    appendGuess,
    guessingFinished,
    revealWinners,
    winners,
    hasPlayerGuessed,
  } = usePlayerScoring({
    players,
    roomInfo,
    sendJsonMessage,
    currentSong,
  });
  const [roundEnded, setRoundEnded] = useState(false);
  const [showGuessingFinishIndicator, setShowGuessingFinishIndicator] =
    useState(false);
  const [hostReconnected, setHostReconnected] = useState();
  const finishIndicatorRef = useRef();
  useHostHeartbeat({ sendJsonMessage, roomInfo });

  useEffect(() => {
    if (guessingFinished) {
      setShowGuessingFinishIndicator(true);
      setTimeout(() => setShowGuessingFinishIndicator(false), 2000);
      return;
    }
  }, [guessingFinished]);

  useEffect(() => {
    if (!roomInfo || !Object.keys(roomInfo).length) return;
    localStorage.setItem("PUBIFY_HOST_ROOMINFO", JSON.stringify({ roomInfo }));
  }, [roomInfo]);

  useEffect(() => {
    const _roomData = localStorage.getItem("PUBIFY_HOST_ROOMINFO");
    if (!_roomData) return;
    const roomData = JSON.parse(_roomData)?.roomInfo;
    console.log("Found room", { roomData });
    sendJsonMessage({
      cmd: "HOST_RECONNECT",
      meta: { uuid: roomData?.uuid, hostKey: roomData?.hostKey },
    });
  }, []);

  useEffect(() => {
    if (!lastJsonMessage) return;

    const { msg, meta, detail, key } = lastJsonMessage;

    conditionalRunner(() => null)(
      { isMatched: () => msg === "ROOM_CREATED", run: () => setRoomInfo(meta) },
      {
        isMatched: () => msg === "PLAYER_JOINED",
        run: () => {
          setPlayers(meta?.players);
          setLatestPlayer(meta?.playerName);
        },
      },
      {
        isMatched: () => msg === "NEW_GUESS",
        run: () => {
          appendGuess(meta);
        },
      },
      {
        isMatched: () => msg === "SCORE_UPDATED",
        run: () => setPlayers(meta?.players),
      },
      {
        isMatched: () => msg === "ERROR",
        run: () => {
          if (key === "RECONNECT_FAILED") {
            toast(detail);
            localStorage.removeItem("PUBIFY_HOST_ROOMINFO");
          }
        },
      },
      {
        isMatched: () => msg === "HOST_RECONNECT_ACK",
        run: () => {
          setRoomInfo(meta);
          setPlayers(meta?.players);
          setRoundNumber(meta?.roundNumber);
          setHostReconnected(true);
        },
      },
      {
        isMatched: () => msg === "CLIENT_RECONNECTED",
        run: () => {
          const player = meta.playerName;
          if (!hasPlayerGuessed(player)) {
            sendJsonMessage({
              cmd: "REMIND_GUESS",
              meta: {
                playerName: player,
                hostKey: roomInfo.hostKey,
                uuid: roomInfo?.uuid,
              },
            });
          }
        },
      }
    );
  }, [lastJsonMessage]);

  useEffect(() => {
    if (!player || !hostReconnected || !songs?.length || !roundNumber) return;
    player.activateElement(); // Safari fix
    fetchSpotify(
      `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
      {
        method: "PUT",
        body: JSON.stringify({
          uris: [songs[roundNumber - 1].uri],
        }),
      }
    );
  }, [player, hostReconnected, songs]);

  const onNextRoundClick = () => {
    setRoundNumber((old) => {
      const newRoundNumber = old + 1;
      setRoundEnded(false);
      resetScoring();

      sendJsonMessage({
        cmd: "NEW_ROUND",
        meta: { uuid: roomInfo?.uuid, hostKey: roomInfo?.hostKey },
      });

      setRoomInfo((old) => ({
        ...old,
        roomState: "ROUND_IN_PROGRESS",
      }));

      player.activateElement(); // Safari fix
      fetchSpotify(
        `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
        {
          method: "PUT",
          body: JSON.stringify({
            uris: [songs[newRoundNumber - 1].uri],
          }),
        }
      ).then(
        (data) => {
          if (data?.error?.status === 403) {
            // Just try once more
            setTimeout(() => {
              fetchSpotify(
                `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
                {
                  method: "PUT",
                  body: JSON.stringify({
                    uris: [songs[newRoundNumber - 1].uri],
                  }),
                }
              ).then((data) => {
                if (data?.error) {
                  toast.error(data?.error?.message);
                }
              });
            }, 2000);
          }
        },
        () => toast.error("Playback failed")
      );
      return newRoundNumber;
    });
  };

  const onPauseClick = () => {
    fetchSpotify(
      `https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`,
      {
        method: "PUT",
      }
    );
  };

  const onPlayClick = () => {
    fetchSpotify(
      `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
      {
        method: "PUT",
      }
    );
  };

  const onWarningClick = () => {
    fetchSpotify(
      `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
      {
        method: "PUT",
        body: JSON.stringify({
          uris: [songs[roundNumber - 1].uri],
        }),
      }
    );
  };

  return (
    <>
      <CSSTransition
        nodeRef={finishIndicatorRef}
        timeout={200}
        in={showGuessingFinishIndicator}
        classNames={"backdrop"}
      >
        <div className="backdrop" ref={finishIndicatorRef}>
          <UIIcon name={"check-circle"} />
          <p>All guesses received!</p>
        </div>
      </CSSTransition>
      <div className={c("host-container", roomInfo && "full-size")}>
        {!isSpotifyAuthenticated && (
          <div className="connect-spotify-button" onClick={showSpotifyAuth}>
            <i className="fab fa-spotify"></i> Connect with Spotify
          </div>
        )}
        {!roomInfo && isSpotifyAuthenticated && (
          <CreateRoomStep
            onCreateClick={({ roomName }) =>
              sendJsonMessage({ cmd: "CREATE_ROOM", meta: { roomName } })
            }
          />
        )}
        {roomInfo && (
          <HostGameController
            roomInfo={roomInfo}
            players={players}
            guessingFinished={guessingFinished}
            // revealWinners={revealWinners}
            onShowSolutionClick={() => {
              setRoundEnded(true);
              revealWinners();
              setRoomInfo((old) => ({ ...old, roomState: "ROUND_FINISHED" }));
            }}
            roundEnded={roundEnded}
            guesses={guesses}
            roundNumber={roundNumber}
            onNextRoundClick={onNextRoundClick}
            currentSong={currentSong}
            winners={winners}
            onPauseClick={onPauseClick}
            onPlayClick={onPlayClick}
            onWarningClick={onWarningClick}
            onPlayerKickClick={onPlayerKickClick}
          />
        )}
      </div>
    </>
  );
};
