import React from "react";
import { splitArray } from "../../../../../../../../utils";
import { PlayerGuess } from "./player-guess";

export const GuessOverview = ({ guesses, currentSong, winners }) => {
  const results = splitArray(
    guesses?.map(({ player, value }) => {
      return {
        name: player,
        guess: value,
        deviation: (parseInt(currentSong?.year) - parseInt(value)) * -1,
        win: winners?.some((win) => win.player === player),
      };
    })
  );

  return (
    <div className="card score-card">
      {results.map((curr, index) => {
        return (
          <div className="score-row" key={index}>
            {curr.map((player, index) => {
              return (
                <PlayerGuess
                  key={index}
                  name={player.name}
                  guess={player.guess}
                  win={player?.win}
                  deviation={player.deviation}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
