import { useSpotifyAuth } from "./use-spotify-auth";
import { useSpotifyPlayer } from "./use-spotify-player";
import { useSpotifySongs } from "./use-spotify-songs";

export const useSpotifyIntegration = () => {
  const {
    showSpotifyAuth,
    fetchSpotify,
    isSpotifyAuthenticated,
    spotifyAccessToken,
  } = useSpotifyAuth();
  const { player, deviceId } = useSpotifyPlayer({ spotifyAccessToken });
  const { songs } = useSpotifySongs({ fetchSpotify });

  return {
    showSpotifyAuth,
    fetchSpotify,
    isSpotifyAuthenticated,
    spotifyAccessToken,
    player,
    deviceId,
    songs,
  };
};
