import React from "react";
import { c } from "../../../utils";
import "./styles.scss";

export const UITextInput = ({
  label,
  defaultValue,
  containerClassName,
  formProps,
  placeholder,
}) => {
  return (
    <div className={c("ui-text-input-container", containerClassName)}>
      {label && <p>{label}</p>}
      <input
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        {...formProps}
      />
    </div>
  );
};
