import React from "react";
import { GuessOverview } from "./components/guess-overview";
import { TrackMeta } from "./components/track-meta";

export const RevealContainer = ({ currentSong, guesses, winners }) => {
  return (
    <div className="reveal-container">
      <TrackMeta currentSong={currentSong} />
      <GuessOverview
        guesses={guesses}
        currentSong={currentSong}
        winners={winners}
      />
    </div>
  );
};
