import React from "react";
import { UIIntro } from "../../../../shared/ui-intro/ui-intro";
import { CreateRoomForm } from "./components/create-room-form/create-room-form";
import "./styles.scss";

export const CreateRoomStep = ({ onCreateClick }) => {
  return (
    <div className="create-room-step">
      <UIIntro />
      <CreateRoomForm
        onCreateSuccess={onCreateClick}
      />
    </div>
  );
};
