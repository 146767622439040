import React, { useState } from "react";
import { HostInterfaceContainer } from "./host-interface-container/host-interface-container";
import { PlayerInterfaceContainer } from "./player-interface-container/player-interface-container";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import "./styles.scss";
import { ClientTypeSelector } from "./client-type-selector/client-type-selector";
import { UIIcon } from "../shared/ui-icon/ui-icon";
import { c } from "../../utils";
import { useParams } from "react-router-dom";
import { HostFrontendConfigProvider } from "./host-interface-container/components/host-frontend-config-provider/host-frontend-config-provider";

const socketUrl = import.meta.env.VITE_PUBIFY_SOCKET_URL || `ws://${window.location.hostname}:3000/socket`;

const Bubbles = () => {
  return (
    <div className="bubbles-container">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <circle cx="0%" cy="0%" r="200" fill="#5A82FC" className="top-start" />
        <circle cx="100%" cy="0%" r="450" fill="#FC5AD8" className="top-end" />
        <circle
          cx="100%"
          cy="100%"
          r="350"
          fill="#5A82FC"
          className="bottom-end"
        />
        <circle
          cx="0%"
          cy="100%"
          r="300"
          fill="#FC5AD8"
          className="bottom-start"
        />
      </svg>
    </div>
  );
};

const ConnectionState = ({ readyState }) => {
  return (
    <div className="connection-status">
      {readyState === 1 && (
        <>
          <UIIcon name={"check-circle"} className="connected" />
          <p>Connected</p>
        </>
      )}
      {readyState !== 1 && (
        <>
          <UIIcon name={"times-circle"} className="error" />
          <p>Error while connecting</p>
        </>
      )}
    </div>
  );
};

export const GameManager = () => {
  const { clientType: typeFromParams } = useParams();
  const [clientType, setClientType] = useState(() => {
    if (typeFromParams === "host") {
      return "HOST";
    }
    if (typeFromParams === "client") {
      return "CLIENT";
    }
    return null;
  });

  const {
    // sendMessage,
    sendJsonMessage,
    // lastMessage,
    lastJsonMessage,
    readyState,
    // getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log("opened"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  return (
    <div
      className={c(
        "pubify-manager-container center",
        clientType && clientType.toLowerCase()
      )}
    >
      <Bubbles />
      {!clientType && (
        <>
          <ClientTypeSelector
            connected={readyState === 1}
            setClientType={setClientType}
          />
          <ConnectionState readyState={readyState} />
        </>
      )}
      {clientType === "HOST" && (
        <HostFrontendConfigProvider>
          <HostInterfaceContainer
            sendJsonMessage={sendJsonMessage}
            lastJsonMessage={lastJsonMessage}
          />
        </HostFrontendConfigProvider>
      )}
      {clientType === "PLAYER" && (
        <PlayerInterfaceContainer
          sendJsonMessage={sendJsonMessage}
          lastJsonMessage={lastJsonMessage}
        />
      )}
    </div>
  );
};
