import React, { createContext, useContext } from "react";

const HostFrontendConfigContext = createContext();
export const HostFrontendConfigProvider = ({ children }) => {
  return (
    <HostFrontendConfigContext.Provider>
      {children}
    </HostFrontendConfigContext.Provider>
  );
};

export const useHostFrontendConfig = () => {
  return useContext(HostFrontendConfigContext);
};
