import { useEffect, useRef } from "react";
export const useHostHeartbeat = ({ sendJsonMessage, roomInfo }) => {
  const intervalRef = useRef();
  useEffect(() => {
    if (!roomInfo?.hostKey) return;
    const sendHeartbeat = () => {
      sendJsonMessage({
        cmd: "HOST_ALIVE",
        meta: { uuid: roomInfo?.uuid, hostKey: roomInfo?.hostKey },
      });
    };
    sendHeartbeat();
    intervalRef.current = setInterval(sendHeartbeat, 15000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [sendJsonMessage, roomInfo]);
};
