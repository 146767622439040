import React, { useMemo } from "react";

export const useScoreboard = ({ players, guesses }) => {
  const playersSorted = useMemo(() => {
    if (!players) return;

    return players
      .sort((a, b) => {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        return 0;
      })
      .map((player, index) => ({
        ...player,
        place: index + 1,
        guess: guesses?.some(({ player: _player }) => _player === player.name),
      }));
  }, [players, guesses]);
  return { playersSorted };
};
